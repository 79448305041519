import * as React from 'react';
import {
  Button,
  Spin,
  Steps,
  notification,
  Modal,
  Input,
  Space,
  Typography,
  Result,
  Divider,
  Alert,
  Table,
  message,
} from 'antd';
import {
  TransitionSchema,
  WorkflowInstance,
  TriggerTransition,
  RunActionResult,
  TransitionValidationResult,
  AuthorizeUserPolicy,
  RunActionButton,
  State,
  TriggerSetState,
  RunActionStatusResult,
} from '../ts-models';
import {
  RightCircleTwoTone,
  LeftCircleTwoTone,
  PullRequestOutlined,
  PlayCircleOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  CodeTwoTone,
  BulbOutlined,
  WarningTwoTone,
  SmileOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useWorkflowDetailView } from './use-workflow-detailview';
import { IWorkflow } from '../ts-models';
import { useParams } from 'react-router';
import { useFormikContext } from 'formik';
import { useSubmit } from './glow/actions/use-submit';
import { useData } from './glow/actions/use-data';
import { LoadingIndicator } from './loadingIndicator';
import scrollIntoView from 'scroll-into-view';

export function useCurrentWorkflowState() {
  const {
    value: { currentState },
  } = useWorkflowDetailView<IWorkflow>();
  return currentState;
}

export function useCurrentStepPolicies() {
  const {
    value: { currentStatePolicies },
  } = useWorkflowDetailView<IWorkflow>();
  return currentStatePolicies;
}

export function useActiveSections() {
  const state = useCurrentWorkflowState();
  const sections = state?.activeSections as any as string[];

  return sections;
}

export function WorkflowActionButtons({ baseUrl }: { baseUrl: string }) {
  const {
    value: {
      currentState: data,
      entity,
      loading,
      currentStateUserHasPermission,
    },
    reload: refetch,
  } = useWorkflowDetailView<IWorkflow>();
  const workflowInstance = entity.workflowInstance;

  return (
    <Spin spinning={loading === true}>
      {data.runActionButtons ? (
        <Divider plain={true} orientation="left">
          Workflow actions
        </Divider>
      ) : (
        <br />
      )}
      {data.runActionButtons &&
        currentStateUserHasPermission &&
        data.runActionButtons.map((runActionButton) => {
          return (
            <WorkflowAction
              baseUrl={baseUrl}
              runActionButton={runActionButton}
            />
          );
        })}
    </Spin>
  );
}

export function WorkflowControls({ baseUrl }: { baseUrl: string }) {
  const {
    value: {
      workflowSchema: data,
      entity,
      loading,
      currentStateUserHasPermission,
    },
    reload: refetch,
  } = useWorkflowDetailView<IWorkflow>();
  const workflowInstance = entity.workflowInstance;

  return (
    <Spin spinning={loading === true}>
      {data.transitions
        .filter((t) => t.fromState == workflowInstance?.currentStateId)
        .map((transition) => {
          return (
            <WorkflowTransition
              key={`transition_${transition.id}`}
              transition={transition}
              workflowInstance={workflowInstance}
              refetch={refetch}
              baseUrl={baseUrl}
              disabled={!currentStateUserHasPermission}
            />
          );
        })}
    </Spin>
  );
}

function WorkflowTransition({
  transition,
  workflowInstance,
  refetch,
  baseUrl,
  disabled,
}: {
  transition: TransitionSchema;
  workflowInstance: WorkflowInstance;
  refetch: Function;
  baseUrl: string;
  disabled: boolean;
}) {
  const { setErrors, submitForm, isValid, setStatus } = useFormikContext();

  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  // const [
  //   validationResult,
  //   setValidationResult,
  // ] = useState<TransitionValidationResult | null>(null)
  const [transitionMessage, setTransitionMessage] = useState<
    string | undefined
  >(undefined);

  const [disableOkWarning, setDisableOkWarning] = useState<boolean | undefined>(
    transition.warningMessage != null,
  );

  const [submit, validate] = useSubmit<TriggerTransition>(
    `${baseUrl}/trigger-transition`,
  );

  const [submitValidate] = useSubmit<TransitionValidationResult>(
    `${baseUrl}/validate-transition`,
  );

  const validateAndShow = async () => {
    setLoading(true);

    var submitResult = await submitForm();

    //console.log("submitResult", submitResult)
    var result = await submitValidate(triggerTransition);
    if (result.ok) {
      if (!result.payload.isValid) {
        notification.warning({
          message: 'Form validation errors. Please see form for details.',
        });
        setStatus(result.payload.invalidFields);
        setStatus(result.payload.invalidFields);
        // double setStatus because of some formik error - status is not set on a fresh entity the first invocation
      } else {
        setStatus(undefined);
        setModalVisible(true);
      }
    } else {
      notification.error({ message: JSON.stringify(result.error) });
    }
    setLoading(false);
  };

  const triggerTransition = {
    WorkflowInstanceId: workflowInstance.id,
    TransitionSchemaId: transition.id,
    TransitionMessage: transitionMessage,
  };

  // useEffect(() => {
  //   const validateData = async () => {
  //     if (modalVisible) {
  //       setLoading(true)

  //       var result = await submitValidate(triggerTransition)
  //       if (result.ok) {
  //         console.log("validationResult", result.payload)
  //         setErrors(result.payload.invalidFields)
  //         setValidationResult(result.payload)
  //       } else {
  //         //setValidationResult( {isValid:false});
  //       }

  //       setLoading(false)
  //     }
  //   }

  //   validateData()
  // }, [modalVisible])

  async function onOk() {
    setLoading(true);
    try {
      var result = await submit(triggerTransition);

      if (result.ok) {
        refetch();
        onCancel();
        setModalVisible(false);
      } else {
        setError(
          `${result.error.title} (${result.error.status}): ${result.error.detail}`,
        );
      }
    } catch (e) {
      //notification.error({ message: E.toString() })
      if (e instanceof Error) {
        setError(e.message);
      }
    }
    setLoading(false);
  }

  function onCancel() {
    setTransitionMessage(undefined);
    setModalVisible(false);
    setError(null);
  }

  return (
    <div key={transition.displayName!}>
      <Modal
        title="Change workflow state"
        open={modalVisible}
        footer={[
          <div key={transition.displayName!} style={{ marginBottom: 10 }}>
            {transition.needsComment && (
              <Input.TextArea
                rows={4}
                name="comment"
                placeholder="Please comment this action."
                onChange={(e: {
                  target: { value: React.SetStateAction<string | undefined> };
                }) => {
                  setTransitionMessage(e.target.value);
                }}
                value={transitionMessage}
                showCount={true}
                maxLength={300}
              ></Input.TextArea>
            )}
          </div>,
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={onOk}
            loading={loading}
            disabled={
              //(validationResult != null && !validationResult.isValid) ||
              (transition.needsComment && !transitionMessage) ||
              disableOkWarning
            }
          >
            Submit
          </Button>,
        ]}
        onCancel={onCancel}
      >
        {/* {validationResult == null ? (
          <div>...</div>
        ) : !validationResult.isValid ? (
          <pre>
            ERROR! Please fill in following fields:{" "}
            {JSON.stringify(validationResult.invalidFields, null, 4)}
          </pre>
        ) : ( */}
        {transition.warningMessage && (
          <div style={{ marginBottom: 20 }}>
            {' '}
            <Alert
              icon={<BulbOutlined />}
              showIcon={true}
              message={<span>Before proceeding...</span>}
              description={
                <Space>
                  <span>{transition.warningMessage}</span>
                  <Button
                    size="small"
                    type="default"
                    onClick={() => setDisableOkWarning(false)}
                  >
                    Ok
                  </Button>
                </Space>
              }
              type="warning"
              closable={false}
            />
          </div>
        )}
        <div>
          <p>Are you sure to perform "{transition.displayName}"?</p>
          {error && (
            <pre>
              <code style={{ maxHeight: 300, display: 'block' }}>{error}</code>
            </pre>
          )}
        </div>
        {/* )} */}
      </Modal>
      <Button
        icon={renderIcon(transition)}
        disabled={!isValid || disabled}
        loading={loading}
        type="link"
        onClick={() => validateAndShow()}
      >
        {transition.displayName}
      </Button>
    </div>
  );
}

export function WorkflowAction({
  //sendMailRequest,
  runActionButton,
  baseUrl,
}: {
  //sendMailRequest?: { mailTemplate: "Test" | "A1toA2" }
  runActionButton: RunActionButton;
  baseUrl: string;
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [result, setResult] = useState<string | null>(null);
  const [statusQueryGetUri, setStatusQueryGetUri] = useState<string | null>(
    null,
  );

  const { id } = useParams();

  const [submitRunAction, validateRunAction] = useSubmit<RunActionResult>(
    `${baseUrl}/${id}/run-action`,
  );

  const [submitSendMail, validateSendMail] = useSubmit<RunActionResult>(
    `${baseUrl}/${id}/send-mail`,
  );

  const {
    value: { workflowSchema: data, entity },
  } = useWorkflowDetailView<IWorkflow>();
  const { workflowInstance } = entity;

  async function onOk() {
    setLoading(true);
    try {
      // const result = runActionRequest
      //   ? await submitRunAction(runActionRequest)
      //   : await submitSendMail(sendMailRequest)

      const result = await submitRunAction({
        action: runActionButton.runActionType,
      });

      if (result.ok) {
        if (result.payload.isSuccessful) {
          setSuccess(true);

          result.payload.statusQueryGetUri &&
            setStatusQueryGetUri(result.payload.statusQueryGetUri);

          result.payload.message
            ? setResult(result.payload.message)
            : setResult('Action was successfuly executed.');
          // result.payload.responseObject
          //   ? setResult(JSON.stringify(result.payload.responseObject, null, 2))
          //   : setResult(result.payload.message)
        } else {
          setSuccess(false);
          setResult(result.payload.message);
        }
      } else {
        setSuccess(false);
        setResult(
          `${result.error.title} (${result.error.status}): ${result.error.detail}`,
        );
      }
    } catch (e) {
      setSuccess(false);

      if (e instanceof Error) {
        setResult(e.message);
      }
    }
    setLoading(false);
  }

  function onCancel() {
    setModalVisible(false);
    setSuccess(null);
    setResult(null);
  }

  return (
    <div>
      <Modal
        title="Init workflow action"
        width={'50%'}
        open={modalVisible}
        footer={
          success === null && [
            <div key="initWorkflowButtons" style={{ marginBottom: 10 }}></div>,
            <Button key="back" onClick={onCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={onOk}
              loading={loading}
            >
              {runActionButton.buttonLabel}
            </Button>,
          ]
        }
        onCancel={onCancel}
      >
        {success === null ? (
          <p>Are you sure to perform "{runActionButton.buttonLabel}"?</p>
        ) : (
          <Result
            style={{ padding: '0px' }}
            icon={statusQueryGetUri ? <></> : undefined}
            status={
              statusQueryGetUri ? undefined : success ? 'success' : 'error'
            }
            title={
              statusQueryGetUri
                ? undefined
                : success
                ? 'Action successful'
                : 'Action Failed'
            }
            subTitle={
              statusQueryGetUri
                ? undefined
                : success
                ? 'You can close this dialog now.'
                : 'Please try again or contact your administrator.'
            }
            extra={
              statusQueryGetUri
                ? []
                : success
                ? [
                    <Button key="Close" onClick={() => onCancel()}>
                      Close
                    </Button>,
                  ]
                : [
                    <Button key="Close" onClick={() => onCancel()}>
                      Close
                    </Button>,
                    <Button
                      key="TryAgain"
                      onClick={() => {
                        setSuccess(null);
                        setResult(null);
                      }}
                    >
                      Try Again
                    </Button>,
                  ]
            }
          >
            <div className="desc" style={{ margin: '-10px' }}>
              {' '}
              {!statusQueryGetUri ? (
                <>
                  <Typography.Paragraph>
                    <Typography.Text
                      strong
                      style={{
                        fontSize: 16,
                      }}
                    >
                      While executing following message was returned:
                    </Typography.Text>
                  </Typography.Paragraph>
                  <Typography.Paragraph
                    ellipsis={{
                      rows: 3,
                      expandable: true,
                    }}
                  >
                    {result}
                  </Typography.Paragraph>
                </>
              ) : (
                <div>
                  {statusQueryGetUri && (
                    <StatusQuery statusQueryUri={statusQueryGetUri} />
                  )}
                </div>
              )}
            </div>
          </Result>
        )}
      </Modal>
      <Button
        icon={<CodeTwoTone />}
        type="link"
        onClick={() => setModalVisible(true)}
      >
        {runActionButton.buttonLabel}
      </Button>
    </div>
  );
}

function StatusQuery({ statusQueryUri }: { statusQueryUri: string }) {
  const [triggerSubmit, setTriggerSubmit] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [error, setError] = useState<string>();
  // const [statusQueryUriResult, setStatusQueryUriResult] = useState<
  //   StatusQueryUriResult | undefined
  // >();

  const { data, refetch, status } = useData<RunActionStatusResult>(
    `${statusQueryUri}`,
    {
      customStatus: { logEntries: [], createdResource: null },
      runtimeStatus: null,
      output: null,
    },
  );

  const activeSlideRef = React.useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await refetch();

        if (!result.isSuccess) {
          //   setStatusQueryUriResult(result.data);
          // } else {
          setError(result.error.title);
          clearInterval(intervalId);
        }
      } catch (error) {
        setError(error?.toString());
        clearInterval(intervalId);
      }
    };

    if (triggerSubmit != 0) {
      fetchData();
    }
  }, [triggerSubmit]);

  useEffect(() => {
    if (data?.runtimeStatus == 'Completed' || data?.runtimeStatus == 'Failed') {
      clearInterval(intervalId);
    }

    activeSlideRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }, [data]);

  useEffect(() => {
    if (intervalId == undefined) {
      const interval = setInterval(() => {
        setTriggerSubmit(Math.random());
      }, 2000);

      setIntervalId(interval);
    }
  }, [intervalId]);

  return (
    <div>
      <div
        style={{ fontWeight: '600', fontSize: '20px', marginBottom: '10px' }}
      >
        {data.runtimeStatus == undefined ||
          (data.runtimeStatus == 'Running' && (
            <>
              <LoadingIndicator loadingText="Action running..." />
            </>
          ))}
        {data.runtimeStatus == 'Completed' && (
          <>
            <CheckCircleTwoTone twoToneColor="#52c41a" /> Action successful
          </>
        )}
        {data.runtimeStatus == 'Failed' && (
          <>
            <WarningTwoTone twoToneColor="#ff4d4f" /> Action failed
            <Alert
              type="error"
              description={JSON.stringify(data.output)}
              style={{ fontWeight: 400, marginTop: 10 }}
            />
          </>
        )}
      </div>
      {error && (
        <pre>
          <code style={{ maxHeight: 300, display: 'block', color: 'red' }}>
            {error}
          </code>
        </pre>
      )}

      {data.customStatus &&
        data.customStatus?.createdResource &&
        data?.runtimeStatus == 'Completed' && (
          <div style={{ marginTop: '10px', marginBottom: '20px' }}>
            Created resource:{' '}
            <a href={data.customStatus.createdResource} target="_blank">
              Go to resource
            </a>
          </div>
        )}
      {data.customStatus &&
        data.customStatus.logEntries &&
        data.customStatus.logEntries.length > 0 && (
          <Table
            columns={[
              {
                dataIndex: 'message',
                title: 'Message',
                key: 'message',
                ellipsis: true,
                render: (text, record, i) => (
                  <span
                    ref={
                      data.customStatus?.logEntries &&
                      i === data.customStatus?.logEntries.length - 1
                        ? activeSlideRef
                        : null
                    }
                  >
                    {text}
                  </span>
                ),
              },
            ]}
            scroll={{ y: 300 }}
            dataSource={data.customStatus.logEntries.map((x) => ({
              message: x,
            }))}
            showHeader={true}
            bordered={true}
            size="small"
            pagination={false}
          />
        )}
    </div>
  );
}

function SetStatus({
  state,
  workflowInstance,
  baseUrl,
  setSetStatusIsOn,
}: {
  state: State | null;
  workflowInstance: WorkflowInstance;
  baseUrl: string;
  setSetStatusIsOn: (on: boolean) => void;
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [transitionMessage, setTransitionMessage] = useState<
    string | undefined
  >(undefined);
  const [submit, validate] = useSubmit<TriggerSetState>(
    `${baseUrl}/trigger-setState`,
  );
  const { reload: refetch } = useWorkflowDetailView<IWorkflow>();

  useEffect(() => {
    setModalVisible(state != null);
  }, [state]);

  async function onOk() {
    setLoading(true);
    try {
      let triggerSetState: TriggerSetState = {
        workflowInstanceId: workflowInstance.id,
        type: null,
        state: state!,
      };
      var result = await submit(triggerSetState);
      setSetStatusIsOn(false);
      refetch();
      onCancel();
      setModalVisible(false);
    } catch (e) {
      if (e instanceof Error) {
        notification.error({ message: e.message });
      }
    }
    setLoading(false);
  }

  function onCancel() {
    setTransitionMessage(undefined);
    setModalVisible(false);
  }

  return (
    <div>
      <Modal
        title="Change workflow state"
        open={modalVisible}
        footer={[
          ,
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={onOk} loading={loading}>
            Submit
          </Button>,
        ]}
        onCancel={onCancel}
      >
        <p>Are you sure to set state to "{state?.displayName}"?</p>
      </Modal>
    </div>
  );
}

function renderIcon(transitionSchema: TransitionSchema) {
  return transitionSchema?.additionalProperties?.isBackwards == true ? (
    <LeftCircleTwoTone twoToneColor="#d02809" />
  ) : (
    <RightCircleTwoTone twoToneColor="#52c41a" />
  );
}

export function RenderRoleIcon({
  authorizeUserPolicy,
}: {
  authorizeUserPolicy: AuthorizeUserPolicy;
}) {
  return authorizeUserPolicy?.userHasPermission == true ? (
    <CheckCircleTwoTone twoToneColor="#52c41a" style={{ marginRight: 10 }} />
  ) : (
    <CloseCircleTwoTone twoToneColor="#bbbbbb" style={{ marginRight: 10 }} />
  );
}

export function WorkflowSteps({ baseUrl }: { baseUrl: string }) {
  const {
    value: { workflowSchema: data, entity, currentStatePolicies },
  } = useWorkflowDetailView<IWorkflow>();
  const { workflowInstance } = entity;

  const [setStatusIsEnabled, setSetStatusIsEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (currentStatePolicies != null && currentStatePolicies.length > 0) {
      setSetStatusIsEnabled(
        currentStatePolicies.find(
          (policy) =>
            (policy.name == 'Administrator' &&
              policy.userHasPermission == true) ||
            (policy.name == 'Provisioning Operator' &&
              policy.userHasPermission == true) ||
            (policy.name == 'Factory Coordinator' &&
              policy.userHasPermission == true),
        ) != null,
      );
    }
  }, [currentStatePolicies]);

  const [setStatusIsOn, setSetStatusIsOn] = useState<boolean>(false);
  const [setStatusClickedState, setSetStatusClickedState] =
    useState<State | null>(null);

  return data.states ? (
    <div>
      <SetStatus
        state={setStatusClickedState}
        workflowInstance={workflowInstance}
        baseUrl={baseUrl}
        setSetStatusIsOn={setSetStatusIsOn}
      />
      <h3 style={{ marginTop: 20 }}>
        Workflow{' '}
        {setStatusIsEnabled && (
          <Button
            size="small"
            icon={<PullRequestOutlined />}
            type={!setStatusIsOn ? 'text' : 'primary'}
            onClick={() => setSetStatusIsOn(!setStatusIsOn)}
          >
            Set
          </Button>
        )}
      </h3>
      <Steps
        size="small"
        direction="vertical"
        progressDot={(dot: JSX.Element) => {
          return setStatusIsOn ? <PlayCircleOutlined /> : dot;
        }}
        current={setStatusIsOn ? 9999 : undefined}
      >
        {data.states.map((state) => {
          return (
            <Steps.Step
              title={
                <Typography.Text ellipsis={true}>
                  {state.displayName}
                </Typography.Text>
              }
              className={setStatusIsOn ? 'hoverAble' : undefined}
              key={state.id}
              status={
                workflowInstance.currentStateId == state.id
                  ? 'process'
                  : setStatusIsOn
                  ? undefined
                  : workflowInstance.currentStateId > state.id
                  ? 'finish'
                  : undefined
              }
              onClick={
                setStatusIsOn
                  ? () => setSetStatusClickedState(state)
                  : undefined
              }
            />
          );
        })}
      </Steps>
    </div>
  ) : (
    <div>...</div>
  );
}

export function WorkflowPermissions({}: {}) {
  const currentStatePolicies = useCurrentStepPolicies();

  return (
    <div>
      <h4>User Roles</h4>
      {currentStatePolicies?.map((policy) => (
        <div>
          <RenderRoleIcon authorizeUserPolicy={policy} /> {policy.name}
        </div>
      ))}
    </div>
  );
}
