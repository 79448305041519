import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { Col, Row, Select, Tabs } from 'antd';
import { LaptopOutlined, PartitionOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import type { TabsProps } from 'antd';

import { PredefinedFilters } from '../generic/table/predefined-filters';
import { useEffect, useState } from 'react';
import { OperationsIntuneListUsers } from './list_users';
import { OperationsIntuneListApps } from './list_apps';
import {
  AadUser,
  AzureAdDevice,
  defaultUserScope,
  ManagedDevice,
  MobileApp,
  UserScope,
} from '../ts-models';
import { AssignToUser } from './assignToUser';
import { useData } from '../generic/glow/actions/use-data';
import { OperationsIntuneListManagedDevice } from './list_devices';
import { AssignToDevice } from './assignToDevice';

export function OperationsIntuneListView() {
  const navigate = useNavigate();

  const [urlPredefinedFilter, setUrlPredefinedFilter] = useState<string>();

  const [leftSideActiveKey, setLeftSideActiveKey] = useState<string>('1');

  const [selectedMobileApps, setMobileApps] = useState<
    MobileApp[] | undefined
  >();
  const [selectedUsers, setSelectedUsers] = useState<AadUser[]>([]);
  const [selectedAzureAdDevices, setSelectedAzureAdDevices] = useState<
    AzureAdDevice[] | undefined
  >();

  const setResetAllLeftSelections = () => {
    setSelectedUsers([]);
    setSelectedAzureAdDevices(undefined);
  };
  const setResetAllRightSelections = () => {
    setMobileApps(undefined);
  };

  useEffect(() => {
    urlPredefinedFilter !== 'microsoft' && setLeftSideActiveKey('1');

    setResetAllLeftSelections();
    setResetAllRightSelections();
  }, [urlPredefinedFilter]);

  return (
    <div>
      <Header
        title={
          <span>
            <LaptopOutlined /> Operations Intune
            <span style={{ marginLeft: 10 }}>
              <ScopeSelector />
            </span>
          </span>
        }
        extra={[
          <PredefinedFilters
            filters={[
              { label: 'Windows', key: 'microsoft' },
              { label: 'iOS', key: 'ios' },
              { label: 'Android', key: 'android' },
            ]}
            urlSetter={setUrlPredefinedFilter}
          />,
          <div style={{ marginLeft: 20 }}>
            <AssignToUser
              users={selectedUsers}
              mobileApp={selectedMobileApps ? selectedMobileApps[0] : undefined}
              setSelectedUsers={setSelectedUsers}
              platform={
                urlPredefinedFilter == 'ios'
                  ? 'ios'
                  : urlPredefinedFilter == 'android'
                  ? 'android'
                  : 'microsoft'
              }
            />
            <AssignToDevice
              devices={selectedAzureAdDevices}
              mobileApp={selectedMobileApps ? selectedMobileApps[0] : undefined}
              setSelectedAzureAdDevices={setSelectedAzureAdDevices}
            />
          </div>,
        ]}
      />
      <div>
        <Row>
          <Col span={10}>
            {/* <div>{JSON.stringify(selectedUser)}</div> */}
            <Tabs
              type="card"
              size="small"
              onChange={(key) => {
                setLeftSideActiveKey(key);
                setResetAllLeftSelections();
              }}
              tabBarStyle={{
                marginTop: 20,
                marginLeft: 20,
                marginRight: 20,
                marginBottom: 0,
              }}
              activeKey={leftSideActiveKey}
              items={[
                {
                  key: '1',
                  label: `Users`,
                  children: (
                    <OperationsIntuneListUsers
                      urlPredefinedFilter={urlPredefinedFilter || ''}
                      setSelectedUsers={setSelectedUsers}
                      selectedUsers={selectedUsers || []}
                    />
                  ),
                },
                {
                  key: '2',
                  label: `Devices`,
                  disabled: urlPredefinedFilter !== 'microsoft',
                  children: (
                    <OperationsIntuneListManagedDevice
                      urlPredefinedFilter={urlPredefinedFilter || ''}
                      setSelectedAzureAdDevices={setSelectedAzureAdDevices}
                      selectedAzureAdDevices={selectedAzureAdDevices || []}
                    />
                  ),
                },
              ]}
            />
          </Col>
          <Col span={14}>
            {/* <div>{JSON.stringify(selectedMobileApp)}</div> */}
            <div style={{ marginTop: 56 }}>
              <OperationsIntuneListApps
                urlPredefinedFilter={urlPredefinedFilter || ''}
                setMobileApps={setMobileApps}
                selectedMobileApps={selectedMobileApps}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

function ScopeSelector() {
  const { data: myScopes, isLoading } = useData<UserScope[]>(
    `/api/operations/my-scopes`,
    [defaultUserScope],
  );

  const { scope } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!scope && myScopes.length > 0) {
      navigate('/ops/' + myScopes[0].id);
    }
  }, [scope, myScopes]);

  return (
    <span>
      <Select
        value={isLoading ? undefined : scope}
        loading={isLoading}
        options={myScopes.map((scope) => ({
          value: scope.id,
          label: scope.name,
        }))}
        style={{ width: 300 }}
        onChange={(value) => navigate('/ops/' + value)}
      />
    </span>
  );
}

const Header = styled(PageHeader)`
  background: #fff;
`;
