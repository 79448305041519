import * as React from 'react';
import {
  Button,
  Modal,
  Descriptions,
  Table,
  Alert,
  notification,
  Tag,
  TabsProps,
  Tabs,
  Skeleton,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  CaretLeftOutlined,
  CodeOutlined,
  GlobalOutlined,
  MinusSquareTwoTone,
  PlusSquareTwoTone,
  WindowsOutlined,
} from '@ant-design/icons';
import {
  Country,
  CountryPortfolioActionResult,
  CountryPortfolioDetailViewDto,
  CountryPortfolioManagePayload,
  defaultCountryPortfolioDetailViewDto,
  defaultMobileApp,
  GenericIntegration,
  MobileApp,
  MobileAppIntegration,
} from '../ts-models';
import { GetField } from '../generic/field';

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router';

import styled from 'styled-components';
import { ActionHistoryTable } from '../generic/ActionHistoryTable';
import { Link } from 'react-router-dom';
import { useSubmit } from '../generic/glow/actions/use-submit';
import { useData } from '../generic/glow/actions/use-data';
import { AppDetails } from '../operations-intune/AppDetails';
import { AppTypeIcon } from './list';
import { Switch, Typography } from 'antd';

const { Paragraph, Text } = Typography;

export const Field = GetField<MobileAppIntegration>();

function CountryManage({
  countries,
  countriesIntune,
  setIntervalCounter,
  isAdd,
  disabled,
  intuneAppFound,
  appType,
}: {
  countries: Country[];
  countriesIntune: Country[];
  setIntervalCounter: (count: number) => void;
  isAdd: boolean;
  disabled: boolean;
  intuneAppFound: boolean;
  appType: string | null;
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formState, setFormState] = useState<
    'loading' | 'error' | 'initial' | 'done'
  >('initial');
  const [targetSystem, setTargetSystem] = useState<
    'ConfigMgr' | 'Intune' | 'none'
  >('none');
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    string | undefined
  >(undefined);
  const [selectedRows, setSelectedRows] = useState<Country[]>([]);
  const [tabItems, setTabItems] = useState<TabsProps['items']>([]);

  let handleCancel = () => {
    onSelectChange([], []);
    setSelectedRows([]);
    setTargetSystem('none');
    setIsModalVisible(false);
  };

  let handleOk = async () => {
    if (selectedRows && selectedRows.length > 0) {
      setFormState('loading');
      let result = await submit({
        targetSystem: targetSystem == 'ConfigMgr' ? 'ConfigMgr' : 'Intune',
        countries: selectedRows,
      });
      setFormState('done');
      if (result.ok) {
        setIsModalVisible(false);
        if (targetSystem == 'ConfigMgr') {
          notification.success({
            duration: 4,
            message: 'Countries are processed in background.',
          });
          setIntervalCounter((selectedRows.length + 1) * 2); // = approximate time to finish request in onprem scriptwrapper
        } else {
          setIntervalCounter(3);
        }
      } else {
        setSubmitErrorMessage(JSON.stringify(result.error));
      }
    }
    setSelectedRows([]);
    setTargetSystem('none');
  };

  useEffect(() => {
    setSelectedRows([]);
  }, [targetSystem]);

  const { id } = useParams();

  const [submit, validate] = useSubmit<
    CountryPortfolioActionResult,
    CountryPortfolioManagePayload
  >(`/api/country-portfolio/${id}/${isAdd ? 'add' : 'remove'}`);

  let onSelectChange = (selectedRowKeys: any, selectedRows: Country[]) => {
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    onChange: onSelectChange,
    getCheckboxProps: (record: Country) => ({
      name: record.displayName || '',
    }),
  };

  useEffect(() => {
    setSelectedRows([]);

    if (appType != 'iOS' && appType != 'Android') {
      setTabItems([
        {
          key: 'Intune',
          label: 'Intune',
          children: <></>,
        },
        {
          key: 'ConfigMgr',
          label: 'ConfigMgr',
          children: <></>,
        },
      ]);
    } else {
      setTabItems([
        {
          key: 'Intune',
          label: 'Intune',
          children: <></>,
        },
      ]);
      setTargetSystem('Intune');
    }
  }, [appType]);

  const onTabsChange = (key: string) => {
    setTargetSystem(key == 'ConfigMgr' ? 'ConfigMgr' : 'Intune');
  };

  return (
    <React.Fragment>
      {isAdd ? (
        <Button
          key="1"
          icon={<PlusSquareTwoTone twoToneColor="#52c41a" />}
          size="middle"
          disabled={disabled}
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Add country
        </Button>
      ) : (
        <Button
          key="2"
          icon={<MinusSquareTwoTone twoToneColor="#ff4d4f" />}
          size="middle"
          disabled={disabled}
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Remove country
        </Button>
      )}
      <Modal
        title={isAdd ? 'Add Countries' : 'Remove Countries'}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ loading: formState == 'loading' }}
      >
        <Tabs
          activeKey={targetSystem == 'none' ? '99' : targetSystem}
          items={tabItems}
          onChange={onTabsChange}
        />

        {targetSystem == 'none' && (
          <Alert
            message="Please choose between ConfigMgr or Intune."
            type="info"
            showIcon
          />
        )}
        {submitErrorMessage && (
          <Alert
            message="Error"
            type="error"
            description={submitErrorMessage}
          />
        )}
        {targetSystem == 'Intune' && intuneAppFound == false ? (
          <CannotFindIntuneApp />
        ) : (
          targetSystem !== 'none' && (
            <Table<Country>
              key={targetSystem}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              rowKey="id"
              size="small"
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'displayName',
                  render: (value: any, record: Country) => (
                    <a>{record?.displayName || 'n/a'}</a>
                  ),
                },
              ]}
              dataSource={
                targetSystem == 'ConfigMgr' ? countries : countriesIntune
              }
              pagination={false}
              scroll={{ y: 300 }}
            />
          )
        )}
      </Modal>
    </React.Fragment>
  );
}

export function CountryPortfolioIntuneMobileApp({
  setIntuneAppFound,
}: {
  setIntuneAppFound: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { id } = useParams();
  const [ellipsis, setEllipsis] = useState(true);

  const { data, refetch, status, error } = useData<MobileApp>(
    `/api/country-portfolio/${id}/intuneMobileApp`,
    defaultMobileApp,
  );

  useEffect(() => {
    if (status == 'success' && data.id) {
      setIntuneAppFound(true);
    }
  }, [data, status]);

  return (
    <>
      {status == 'loading' && (
        <Skeleton.Button active={true} size={'small'} block={false} />
      )}
      {status == 'success' && (
        <>
          {data.id ? (
            <AppDetails mobileApp={data} showFullDisplayName={true} />
          ) : (
            <CannotFindIntuneApp />
          )}
        </>
      )}
      {error && (
        <Alert
          showIcon={true}
          message={
            <Paragraph
              ellipsis={
                ellipsis ? { rows: 2, expandable: true, symbol: 'more' } : false
              }
            >
              Cannot get Intune Mobile App
            </Paragraph>
          }
          type="error"
        />
      )}
    </>
  );
}

export function CannotFindIntuneApp() {
  return (
    <Alert
      showIcon={true}
      message="Cannot find Intune Mobile App by SIM Request Id. Please fill in Notes in Intune Mobile App (Cache is 6h)."
      type="error"
    />
  );
}

export function CountryPortfolioDetailView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [intervalNumber, setIntervalNumber] = useState<number | null>(null);
  const [intervalCounter, setIntervalCounter] = useState<number>(0);
  const [intervalState, setIntervalState] = useState<'enabled' | 'disabled'>(
    'disabled',
  );
  const [intuneAppFound, setIntuneAppFound] = useState<boolean>(false);

  const {
    data: entity,
    refetch: refetchDetailview,
    status,
  } = useData<CountryPortfolioDetailViewDto>(
    `/api/country-portfolio/${id}`,
    defaultCountryPortfolioDetailViewDto,
  );

  useEffect(() => {
    if (intervalCounter > 0) {
      const asyncFunc = async () => {
        await delay(intervalCounter * 1000);
        refetchDetailview();
        setIntervalCounter(0);
      };

      asyncFunc();
    }
  }, [intervalCounter]);

  return (
    <div>
      <HeaderTop
        title={
          <div>
            <span>
              <GlobalOutlined /> Country Portfolio
            </span>
            <Button
              icon={<CaretLeftOutlined />}
              onClick={() => navigate('..')}
              style={{ marginLeft: 10, marginRight: 10 }}
              size="small"
            />{' '}
            {entity.displayName}
          </div>
        }
        extra={[
          <Button
            size="middle"
            loading={intervalCounter > 0}
            onClick={() => setIntervalCounter(1)}
          >
            Refresh
          </Button>,
          <CountryManage
            key="add"
            countries={entity.countriesCanAdd}
            countriesIntune={entity.countriesCanAddIntune}
            disabled={
              entity.countriesCanAdd.length == 0 &&
              entity.countriesCanAddIntune.length == 0
            }
            setIntervalCounter={setIntervalCounter}
            isAdd={true}
            intuneAppFound={intuneAppFound}
            appType={entity.genericIntegration.type}
          />,
          <CountryManage
            key="remove"
            countries={entity.countriesCanRemove}
            countriesIntune={entity.countriesCanRemoveIntune}
            disabled={
              entity.countriesCanRemove.length == 0 &&
              entity.countriesCanRemoveIntune.length == 0
            }
            setIntervalCounter={setIntervalCounter}
            isAdd={false}
            intuneAppFound={intuneAppFound}
            appType={entity.genericIntegration.type}
          />,
        ]}
      ></HeaderTop>
      <Container>
        <Descriptions
          size="small"
          column={2}
          bordered={true}

          //bordered={false}
          //layout="vertical"
        >
          <Descriptions.Item
            label={`Application details`}
            span={2}
            labelStyle={{ width: 300 }}
          >
            {status == 'loading' && (
              <Skeleton.Button active={true} size={'small'} block={false} />
            )}
            {entity.genericIntegration.type == 'Win10ClassicIntegration' ? (
              <span>
                <Link
                  to={`/integrations/win10-classic/${entity.genericIntegration.id}`}
                >
                  {entity.displayName}
                </Link>
              </span>
            ) : entity.genericIntegration.type == 'iOS' ? (
              <span>
                <Link
                  to={`/integrations/mobile-app/ios/${entity.genericIntegration.id}`}
                >
                  {entity.displayName}
                </Link>
              </span>
            ) : entity.genericIntegration.type == 'Android' ? (
              <span>
                <Link
                  to={`/integrations/mobile-app/android/${entity.genericIntegration.id}`}
                >
                  {entity.displayName}
                </Link>
              </span>
            ) : (
              <span>
                <Link
                  to={`/integrations/win10-app/${entity.genericIntegration.id}`}
                >
                  {entity.displayName}
                </Link>
              </span>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={`Type`} span={2}>
            {status == 'loading' && (
              <Skeleton.Button active={true} size={'small'} block={false} />
            )}
            {status == 'success' && (
              <AppTypeIcon genericIntegration={entity.genericIntegration} />
            )}
          </Descriptions.Item>
          <Descriptions.Item label={`RequestId`} span={2}>
            {status == 'loading' && (
              <Skeleton.Button active={true} size={'small'} block={false} />
            )}
            {entity.genericIntegration.requestID}
          </Descriptions.Item>
          <Descriptions.Item
            label={`Assigned countries ConfigMgr (${entity.countries.length} of ${entity.countriesCountOverall})`}
            span={2}
          >
            {entity.countries.map((country) => (
              <Tag>{country.isoCode}</Tag>
            ))}
          </Descriptions.Item>

          <Descriptions.Item label={`Intune App`} span={2}>
            <CountryPortfolioIntuneMobileApp
              setIntuneAppFound={setIntuneAppFound}
            />
          </Descriptions.Item>

          <Descriptions.Item
            label={`Assigned countries Intune (${entity.countriesIntune.length} of ${entity.countriesCountOverall})`}
            span={2}
          >
            {entity.countriesIntune.map((country) => (
              <Tag>{country.isoCode}</Tag>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="App Owner" span={2}>
            {entity.genericIntegration?.appOwner?.displayName || 'unknown'}
          </Descriptions.Item>
        </Descriptions>

        <h3 style={{ marginTop: 20 }}>Requests history</h3>

        <ActionHistoryTable actionHistory={entity.actionHistory} />
      </Container>
    </div>
  );
}

const Header = styled(PageHeader)`
  padding: 0px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  padding: 20px;
`;

const HeaderTop = styled(PageHeader)`
  background: #fff;
`;

const delay = (ms: number | undefined) =>
  new Promise((res) => setTimeout(res, ms));
